import React, { useState } from "react";
import { get, handleException, post } from "../../network/requests";
import { getUrl } from "../../network/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loader, loaderStop } from "../../reducer/app-reducer";
import { notify } from "../../services/react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { authHeader } from "../../services/auth";
import dateFormat from "dateformat";

export default function TripSummaryMainComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector((state) => state.auth.auth_user);
  const [trip, setTrip] = useState(null);

  const getTripInfo = () => {
    dispatch(loader(true)); // start loader
    get(`${getUrl("draft-trips")}/${params.id}`, authHeader())
      .then((response) => {
        dispatch(loader(false)); // stop loader
        if (!response.data.data) {
          navigate("/");
        }
        setTrip(response.data.data);
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  const confirmTrip = () => {
    dispatch(loader(true)); // start loader
    post(`${getUrl("draft-trips")}/${params.id}/confirm`, {}, authHeader())
      .then((response) => {
        dispatch(loader(false)); // stop loader
        navigate(`/trip/${response.data.data.id}/confirmed`);
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  useState(() => {
    getTripInfo(); // get trip data from API
  }, []);
  return (
    <div className="col-lg-6">
      <div className="container common-container">
        <div className="right-part">
          <p className="head-para">Confirm your trip information</p>
          {trip ? (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Name</label>
                    <p>{user.full_name}</p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Contact Number</label>
                    <p>{user.phone_number}</p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Pick-up</label>
                    <p>{trip.pickup_location_alias}</p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Drop-off Location</label>
                    <p>{trip.drop_location}</p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Pick-up date & time</label>
                    <p>
                      {dateFormat(
                        trip.pick_up_datetime,
                        "mmmm d, yyyy h:MM TT"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Cost</label>
                    <p>{trip.total_cost} NGN</p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Merchant</label>
                    <p>{trip.merchant}</p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="each-confirmed-div">
                    <label htmlFor="pickLocation">Driver</label>
                    <p>{trip.driver}</p>
                  </div>
                </div>
              </div>

              <div className="bottom-btn-main-div mt-3">
                <Link className="btn back-btn" to={`/trip/${params.id}/driver`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="23"
                    viewBox="0 0 34 23"
                    fill="none"
                  >
                    <path
                      d="M0.906917 12.2079C0.321133 11.6221 0.321133 10.6723 0.906918 10.0866L10.4529 0.540613C11.0386 -0.0451737 11.9884 -0.0451736 12.5742 0.540613C13.16 1.1264 13.16 2.07615 12.5742 2.66193L4.0889 11.1472L12.5742 19.6325C13.16 20.2183 13.16 21.168 12.5742 21.7538C11.9884 22.3396 11.0386 22.3396 10.4529 21.7538L0.906917 12.2079ZM33.6611 12.6472L1.96758 12.6472L1.96758 9.64721L33.6611 9.64722L33.6611 12.6472Z"
                      fill="#4D90FE"
                    />
                  </svg>
                  Back
                </Link>
                <a
                  className="btn process-btn active"
                  onClick={() => confirmTrip()}
                >
                  Confirm Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="23"
                    viewBox="0 0 34 23"
                    fill="none"
                  >
                    <path
                      d="M33.3641 12.7079C33.9499 12.1221 33.9499 11.1723 33.3641 10.5866L23.8181 1.04061C23.2323 0.454826 22.2826 0.454826 21.6968 1.04061C21.111 1.6264 21.111 2.57615 21.6968 3.16193L30.1821 11.6472L21.6968 20.1325C21.111 20.7183 21.111 21.668 21.6968 22.2538C22.2826 22.8396 23.2324 22.8396 23.8181 22.2538L33.3641 12.7079ZM0.609863 13.1472L32.3034 13.1472L32.3034 10.1472L0.609863 10.1472L0.609863 13.1472Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
