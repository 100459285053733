import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loader, loaderStop } from '../../reducer/app-reducer';
import { get, handleException } from '../../network/requests';
import { getUrl } from '../../network/url';
import { authHeader } from '../../services/auth';
import { setPayments, setStats } from '../../reducer/auth-reducer';
import { notify } from '../../services/react-toastify';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import 'rsuite/dist/rsuite.min.css';

export default function PaymentsListMainConponent() {
    const stats = useSelector((state) => state.auth.stats);
    const payments = useSelector((state) => state.auth.payments);
    const dispatch = useDispatch();
    const [filterType, setFilterType] = useState('All');
    const [selectedDates, setSelectedDates] = useState(null); // State for storing start and end dates

    const handleDateChange = (dates) => {
        if (dates) {
            setSelectedDates(dates); // Set dates when a valid range is selected
        } else {
            setSelectedDates(null); // Reset to null when cleared
        }
    };

    const getStats = () => {
        if (!stats) {
            dispatch(loader(true));  // start loader
        }
        get(getUrl('stats'), authHeader()).then((response) => {
            if (!stats) {
                dispatch(loader(false));  // stop loader
            }
            dispatch(setStats(response.data.data));
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        })
    }
    const getPayments = () => {
        if (!payments) {
            dispatch(loader(true));  // start loader
        }
        get(getUrl('trip-payments'), authHeader()).then((response) => {
            if (!payments) {
                dispatch(loader(false));  // stop loader
            }
            dispatch(setPayments(response.data.data));
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        })
    }

    // filter trip list
    const paymentList = () => {

        let list = [...payments];

        if (filterType === "Paid") {
            list = list.filter(i => i.status === 'successful');
        }

        if (filterType === "Failed") {
            list = list.filter(i => i.status === 'failed');
        }

        if (selectedDates) {
            let start = moment(selectedDates[0]).startOf('day');
            let end = moment(selectedDates[1]).startOf('day');
            list = list.filter((item) => {
                let date = moment(item.created_at).startOf('day');
                return date >= start && date <= end;
            });
        }

        return list;
    }
    useEffect(() => {
        getStats();
        getPayments();
    }, []);
    return (
        <section className="dashboard-middle-main-section">
            <div className="container common-dashboard-container">
                <div className="head-area-div payment-list-head-area-div">
                    <h1>Payments</h1>

                    <div className="search-filter-div payment-list-right-head">
                        <p className="para">Total Payment {stats ? Number(stats.total_payment).toLocaleString() : 0} NGN</p>
                        <div className="sort-dropdown-div d-flex align-items-center"><label className="me-2">Filter by Date</label>
                            <DateRangePicker onChange={handleDateChange} className="responsive-daterange-picker" placement="autoVerticalStart" style={{ width: 225, height: 40 }} placeholder="Select Date Range" />
                        </div>
                        <div className="sort-dropdown-div d-flex align-items-center"><label className="me-2">Sort by</label>
                            <div className="dropdown sort-dropdown">
                                <button className="btn dropdown-toggle" role="button" id="sortingBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>{filterType}</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="sortingBtn">
                                    <li><Link className={`dropdown-item ${filterType === 'All' ? 'active' : ''}`} to="" onClick={() => setFilterType('All')}>All</Link></li>
                                    <li><Link className={`dropdown-item ${filterType === 'Paid' ? 'active' : ''}`} to="" onClick={() => setFilterType('Paid')}>Paid</Link></li>
                                    <li><Link className={`dropdown-item ${filterType === 'Failed' ? 'active' : ''}`} to="" onClick={() => setFilterType('Failed')}>Failed</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="all-table-main-div">
                    <div className="container-fluid p-0 table-responsive">
                        <table className="customer-list-table">
                            <thead>
                                <tr>
                                    <th>Transaction ID/Date</th>
                                    <th>Trip ID/ Date</th>
                                    <th>Pick-up/Drop-off</th>
                                    <th>Amount (NGN)</th>
                                    <th>Payment Mode</th>
                                    <th>Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    (payments && payments.length) ? paymentList().map((item) => <tr key={item.id} className={!item.trip ? 'd-none' : ''}>
                                        <td>
                                            <div className="pay-list-text-div">
                                                <p>#{item.transaction_id || item.id}</p>
                                                <p>{dateFormat(item.created_at, 'mmmm d, yyyy')}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="pay-list-text-div">
                                                <p>#{item.trip ? item.trip.id : ''}</p>
                                                {item.trip ? <p>{dateFormat(item.trip.created_at, 'mmmm d, yyyy')}</p> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="pay-list-text-div">
                                                {item.trip ? <p>{(item.trip.pickup_location_alias || item.trip.pickup_location) || 'NA'}/</p> : null}
                                                {item.trip ? <p>{item.trip.drop_location || 'NA'}</p> : null}
                                            </div>
                                        </td>
                                        <td>
                                            {item.amount}
                                        </td>
                                        <td>
                                            {item.payment_type}
                                        </td>
                                        <td className={item.status === 'successful' ? 'paid' : 'failed'}>
                                            {item.status === 'successful' ? 'Paid' : 'Failed'}
                                        </td>
                                    </tr>) : null
                                }
                                {
                                    payments && !paymentList().length ? <tr><td colSpan={6} className='text-center'>Payments not found.</td></tr> : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </section>
    )
}
